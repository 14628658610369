import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

// pages & components
import AboutPage from "./pages/AboutPage";
import AlbumPage from "./pages/Album/AlbumPage";
import ArtistAlbumsPage from "./pages/Artist/ArtistAlbumsPage";
import ArtistCreditsIconPage from "./pages/Artist/ArtistCreditsIconPage";
import ArtistCreditsPage from "./pages/Artist/ArtistCreditsPage";
import ArtistDiscoveryListPage from "./pages/ArtistDiscoveryListPage";
import ArtistDiscoveryPage from "./pages/ArtistDiscoveryPage";
import ArtistPage from "./pages/Artist/ArtistPage";
import ArtistSongsPage from "./pages/Artist/ArtistSongsPage";
import FeedbackPage from "./pages/FeedbackPage";
import Home from "./pages/Home/Home";
import LoginPage from "./pages/Login/LoginPage";
import Navbar from "./components/Navbar/Navbar";
import PageNotFoundPage from "./pages/PageNotFoundPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import CreatePlaylistPage from "./pages/Playlist/CreatePlaylistPage";
import ViewPlaylistPage from "./pages/Playlist/ViewPlaylistPage";
import SearchResultsPage from "./pages/SearchResults";
import SongPage from "./pages/Song/SongPage";
import TopCreditsPage from "./pages/TopCreditsPage";
import Footer from "./Footer/Footer";

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <BrowserRouter>
          <Navbar />
          <div className="pages">
            <Routes>
              <Route
                path="/artist/:artist/credits-featured"
                element={<ArtistCreditsIconPage />}
              />
              <Route
                path="/artist/:artist/credits-written"
                element={<ArtistCreditsIconPage />}
              />
              <Route
                path="/artist/:artist/credits-composed"
                element={<ArtistCreditsIconPage />}
              />
              <Route
                path="/artist/:artist/credits-arranged"
                element={<ArtistCreditsIconPage />}
              />
              <Route
                path="/artist/:artist/credits"
                element={<ArtistCreditsPage />}
              />
              <Route path="/artist/:artist/songs" element={<ArtistSongsPage />} />
              <Route
                path="/artist/:artist/albums"
                element={<ArtistAlbumsPage />}
              />

              <Route path="/artist/:artist" element={<ArtistPage />} />
              <Route path="/song/:song" element={<SongPage />} />
              <Route path="/album/:album" element={<AlbumPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/top-credits" element={<TopCreditsPage />} />
              <Route
                path="/artist-discovery/:type"
                element={<ArtistDiscoveryPage />}
              />
              <Route
                path="/artist-discovery-list"
                element={<ArtistDiscoveryListPage />}
              />
              <Route path="/search" element={<SearchResultsPage />} />
              <Route path="/feedback" element={<FeedbackPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/create-playlist" element={<CreatePlaylistPage />} />
              <Route path="/playlist/:playlistId" element={<ViewPlaylistPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<PageNotFoundPage msg={undefined} />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
