import { Link } from "react-router-dom";
import SongDisplayList from "./ViewAll/SongDisplayList";

interface CreditDetailsByTypeProps {
  artist_id: number;
  credits: AllCreditsForSong[];
  type: string;
}

const CreditDetailsByType: React.FC<CreditDetailsByTypeProps> = ({
  artist_id,
  credits,
  type,
}) => {
  return (
    <div className={"credited-" + type}>
      {/* <h5>
        {type.toUpperCase()} ({credits.length}):
      </h5> */}
      <div className="display-list">
        {credits.slice(0, 6).map((credit) => (
          <SongDisplayList
            key={credit._id}
            song={credit}
            name={credit.english_name}
            picture_url={credit.picture_url}
            year={new Date(credit.release_date).getFullYear()}
            artist={credit.artist_english_names}
            artist_ids={credit.artist_ids}
          />
        ))}{" "}
      </div>
      <Link
        className="see-all"
        to={"./credits-" + type}
        state={{ id: artist_id }}
      >
        <u>see all {type}</u>
      </Link>
    </div>
  );
};

export default CreditDetailsByType;
