import { FC, useState } from "react";
import SongDisplayList from "./ViewAll/SongDisplayList";

const SongCarousel: FC<Songs> = ({ songs }) => {
  const english_names = songs.map((song) => song.artist_names);
  const artist_ids = songs.map((song) => song.artist_ids);
  return (
    <div className="recent-songs-details">
      <h4 className="album-header">
        {" "}
        {songs && songs.length > 0 && "new song releases"}
      </h4>
      <div className="display-list">
        {songs.map((song, idx) => (
          <SongDisplayList
            key={song.melon_song_id + english_names[idx].toString()}
            song={song}
            name={song.english_name}
            picture_url={song.picture_url}
            year={new Date(song.release_date)}
            artist={english_names[idx]}
            artist_ids={artist_ids[idx]}
          />
        ))}
      </div>

    </div>
  );
};

export default SongCarousel;