import React from "react";
import CreditDetailsByType from "./CreditDetailsByType";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

interface BasicTabsProps {
  featured: any;
  written: any;
  composed: any;
  arranged: any;
}

const BasicTabs: React.FC<BasicTabsProps> = ({ featured, written, composed, arranged }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={"FEATURED (" + featured.length + ")"} {...a11yProps(0)} />
          <Tab label={"WRITTEN (" + written.length + ")"} {...a11yProps(1)} />
          <Tab label={"COMPOSED (" + composed.length + ")"} {...a11yProps(2)} />
          <Tab label={"ARRANGED (" + arranged.length + ")"} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {featured}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {written}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {composed}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {arranged}
      </CustomTabPanel>
    </Box>
  );
}

const CreditDetails: React.FC<CreditDetailsProps> = React.memo(({
  artist_id,
  credits,
}) => {
  console.log("credit details", credits);
  const featured = credits.filter((c) => c.credit_type.includes("F"));
  const written = credits.filter((c) => c.credit_type.includes("L"));
  const composed = credits.filter((c) => c.credit_type.includes("C"));
  const arranged = credits.filter((c) => c.credit_type.includes("A"));

  const categories = [
    { label: "FEATURED", data: featured },
    { label: "WRITTEN", data: written },
    { label: "COMPOSED", data: composed },
    { label: "ARRANGED", data: arranged },
  ];

  const filteredCategories = categories.filter((category) => category.data.length > 0);
  console.log("filtered", filteredCategories);



  const [value, setValue] = React.useState(0);


  React.useEffect(() => {
    setValue(0); // Always reset to the first valid tab when categories change
  }, [filteredCategories.length]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  return (
    <>
      <div className="credited-details">
        {credits.length > 0 && <h4>song credits</h4>}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {filteredCategories.map((category, index) => (
                <Tab
                  key={category.label}
                  label={`${category.label} (${category.data.length})`}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
          {filteredCategories.map((category, index) => (
            <CustomTabPanel key={category.label} value={value} index={index}>
              <CreditDetailsByType
                artist_id={artist_id}
                credits={category.data}
                type={category.label.toLowerCase()} // Converts to lowercase: "featured", "written", etc.
              />
            </CustomTabPanel>
          ))}
        </Box>
      </div >
    </>
  );
});

export default CreditDetails;
