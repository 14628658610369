import React, { useEffect, useState } from "react";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom"; // If using React Router
import "./Login.css";


const LoginPage: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const navigate = useNavigate();

    // Load user session on page load
    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        const storedToken = localStorage.getItem("token");

        if (storedUser && storedToken) {
            setUser(JSON.parse(storedUser));
            setToken(storedToken);
            // Navigate to profile page if already logged in
            navigate("/profile");
        }
    }, [navigate]);

    const handleSuccess = async (response: any) => {
        try {
            const decoded: DecodedUser = jwtDecode(response.credential);
            console.log("Decoded User Info:", decoded);

            // Send Google token to backend first
            await sendTokenToBackend(decoded);

            // Dispatch custom event for login state change
            window.dispatchEvent(new Event('loginStateChange'));

        } catch (error) {
            console.error("Error decoding token", error);
        }
    };

    const sendTokenToBackend = async (decoded: DecodedUser) => {
        try {
            const response = await fetch("/api/auth/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include", // Needed for cookies
                body: JSON.stringify({
                    googleSub: decoded.sub,
                    email: decoded.email,
                    fullName: decoded.name,
                    firstName: decoded.given_name,
                    picture: decoded.picture,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            console.log("Backend Response:", data);

            // Set user state with backend response data which includes dubu_user
            const userData: User = {
                name: data.user.fullName,
                given_name: data.user.firstName,
                picture: decoded.picture,
                email: data.user.email,
                dubu_user: data.user.dubuUser,
                userId: data.user.userId
            };
            setUser(userData);
            localStorage.setItem("user", JSON.stringify(userData));

            setToken(data.token);
            localStorage.setItem("token", data.token);

            // Navigate to profile page after successful login
            navigate("/profile");
        } catch (error) {
            console.error("Error sending token to backend", error);
        }
    };


    const handleError = () => {
        console.log("Login Failed");
    };

    const handleLogout = async () => {
        try {
            // Clear frontend stored data first
            setUser(null);
            setToken(null);
            localStorage.removeItem("token");
            localStorage.removeItem("user");

            // Dispatch custom event for login state change
            // window.dispatchEvent(new Event('loginStateChange'));

            // Call backend to remove the token cookie
            const response = await fetch("/api/auth/logout", {
                method: "POST",
                credentials: "include", // Include cookies in the request
            });

            if (!response.ok) {
                throw new Error(`Logout failed with status: ${response.status}`);
            }

            console.log("Logged out successfully");

            // Call Google logout last
            try {
                googleLogout();
            } catch (googleError) {
                console.log("Google logout error (non-critical):", googleError);
            }

        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    return (
        <div className="login-page">
            <div className="google-login-container">
                <h2>log into dubu</h2>
                <p><i>Note: this feature is still under development</i></p>
                <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
            </div>

        </div>
    );
};

export default LoginPage;
