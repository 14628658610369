import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"; // Ensure this path is correct
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css"; // Optional: Ensure you have this file

const CLIENT_ID = "1061964519023-1gp5g2es248i9vgcihrkjfqdoo0barvn.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);