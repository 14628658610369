import React from "react";
import { Helmet } from "react-helmet-async";

interface ErrorMessage {
  msg?: string;
}

const PageNotFoundPage: React.FC<ErrorMessage> = ({ msg }) => {
  return (
    <div className="padding">
      <div className="not-found">
        <Helmet>
          <title>404 not found - dubu</title>
        </Helmet>
        <h1 className="main-text">404 Not Found</h1>
        {msg && <p>{msg}</p>}
      </div>
    </div>
  );
};

export default PageNotFoundPage;
