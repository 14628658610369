const TextCardComponent: React.FC<{ description: string }> = ({ description }) => {
    return (
        <div className="text-card-component">
            <h5>{description}</h5>
        </div>
    );
}


export default TextCardComponent;
