import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./Home.css";

// components
import HomeCarousel from "../../components/HomeCarousel/HomeCarousel";
import HomeCarouselMobile from "../../components/HomeCarousel/HomeCarouselMobile";
// import SongDisplayList from "../components/ViewAll/SongDisplayList";
import PageNotFoundPage from "../PageNotFoundPage";
import OnThisDaySongs from "../OnThisDayPage";
import SongCarousel from "../../components/SongCarousel";

const Home = () => {
  const [songs, setSongs] = useState<Song[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRecentSongs = async () => {
      const response = await fetch("/api/songs/recent/" + "7");
      const json = await response.json();

      if (response.ok) {
        setSongs(json);
      } else {
        setError(response.status + " - " + json.error);
      }
    };
    fetchRecentSongs();
  }, []);

  if (error) return <PageNotFoundPage msg={error} />;
  return (
    <div className="home">
      <Helmet>
        <title>home - dubu</title>
        <meta
          name="description"
          content="dubu Homepage: a database of your favorite KPOP idols, albums, songs, and credits."
        />
        <meta name="keywords" content="kpop, dubu, database, kpop credits" />
      </Helmet>
      <div className="carousel-container">
        <HomeCarousel />
      </div>
      <div className="carousel-container-mobile">
        <HomeCarouselMobile />
      </div>
      <div className="padding">
        <div className="home-page-details">
          <OnThisDaySongs />
          {songs && songs[0] && (
            <SongCarousel songs={songs.slice(0, 10)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
