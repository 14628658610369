import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import SongDisplayList from "../../components/ViewAll/SongDisplayList";
import "./Playlist.css";
import "../../types/SongTypes";

interface Song {
    _id: number;
    melon_song_id?: number;
    id?: number;
    name: string;
    artist_ids: number[];
    artist_english_name: string;
    picture_url: string;
    type: string;
}

interface PlaylistSong extends Song {
    isAdded?: boolean;
}

const CreatePlaylistPage: React.FC = () => {
    const [playlistName, setPlaylistName] = useState("");
    const [description, setDescription] = useState("");
    const [isPublic, setIsPublic] = useState(true);
    const [songs, setSongs] = useState<Song[]>([]);
    const [selectedSongs, setSelectedSongs] = useState<PlaylistSong[]>([]);
    const [isCreating, setIsCreating] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is logged in
        const storedUser = localStorage.getItem("user");
        const storedToken = localStorage.getItem("token");

        console.log("Stored user:", storedUser ? JSON.parse(storedUser) : null);
        console.log("Stored token exists:", !!storedToken);

        if (!storedUser) {
            navigate("/login");
            return;
        }

        // Fetch songs for search
        const fetchSongs = async () => {
            try {
                const response = await fetch("/api/songs/search");
                if (!response.ok) {
                    throw new Error(`Failed to fetch songs: ${response.status}`);
                }
                const data = await response.json();

                // Add a type property to each song for the search component
                const songsWithType = data.map((song: Song) => ({
                    ...song,
                    // melon_song_id: song.melon_song_id, 
                    type: "SONG"
                }));

                setSongs(songsWithType);
            } catch (error) {
                console.error("Error fetching songs:", error);
                setError("Failed to load songs. Please try again later.");
            }
        };

        fetchSongs();
    }, [navigate]);

    const handleOnSearch = (string: string, results: any) => {
        // This function can be used for any search-related logic
    };

    const handleOnSelect = (item: PlaylistSong) => {
        try {
            // Log the selected item to see what data we have
            console.log("Selected song:", item);

            // Get the song ID (could be either id or melon_song_id)
            const songId = item.melon_song_id || item.id;

            if (!songId) {
                console.error("Selected song missing ID:", item);
                setError("Cannot add song: missing required ID information");
                return;
            }

            // Check if song is already selected
            const alreadySelected = selectedSongs.some(song => {
                const existingSongId = song.melon_song_id || song.id;
                return existingSongId && songId && existingSongId === songId;
            });

            if (alreadySelected) {
                console.log("Song already selected, skipping:", item.name);
                return;
            }

            // Add song to selected songs, ensuring it has a melon_song_id that the backend expects
            const newSong = {
                ...item,
                melon_song_id: songId, // Explicitly set melon_song_id to the song's ID value
                isAdded: true
            };

            // Use the functional update form to ensure we're working with the latest state
            setSelectedSongs(prevSongs => [...prevSongs, newSong]);
            console.log("Song added to playlist:", item.name);
        } catch (err) {
            console.error("Error adding song:", err);
        }
    };

    const handleRemoveSong = (index: number) => {
        console.log(`Removing song at index ${index}`);
        setSelectedSongs(prevSongs => prevSongs.filter((_, i) => i !== index));
    };

    const formatResult = (item: PlaylistSong) => {
        // Get item ID for comparison (could be either id or melon_song_id)
        const itemId = item.melon_song_id || item.id;

        // Check if this song is already in the selected songs list
        const isAlreadyAdded = selectedSongs.some(song => {
            const songId = song.melon_song_id || song.id;
            return itemId && songId && itemId === songId;
        });

        return (
            <div className="search-result-item">
                <div className="search-result-content">
                    {item.picture_url && (
                        <img
                            src={item.picture_url}
                            alt={item.name}
                            className="search-result-image"
                            onError={(e) => {
                                e.currentTarget.src = "/dubuFace.jpg";
                                e.currentTarget.onerror = null;
                            }}
                        />
                    )}
                    <div className="search-result-info">
                        <div className="search-result-name">{item.name}</div>
                        <div className="search-result-artist">{item.artist_english_name}</div>
                    </div>
                </div>
                <button
                    className="add-song-button"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOnSelect(item);
                    }}
                    disabled={isAlreadyAdded}
                >
                    {isAlreadyAdded ? "Added" : "Add"}
                </button>
            </div>
        );
    };

    const handleCreatePlaylist = async () => {
        // Validate inputs
        if (!playlistName.trim()) {
            setError("Please enter a playlist name");
            return;
        }

        if (selectedSongs.length === 0) {
            setError("Please add at least one song to your playlist");
            return;
        }

        setIsCreating(true);
        setError("");

        try {
            // Extract song IDs from selected songs - make sure we use melon_song_id
            const songIds = selectedSongs.map(song => {
                // Add logging to see what we actually have
                console.log("Song data for playlist creation:", song);
                // The song should already have melon_song_id set from handleOnSelect
                if (!song.melon_song_id) {
                    console.warn("Missing melon_song_id for song:", song);
                }
                return song.melon_song_id;
            });

            // Make sure we don't have any null/undefined values
            if (songIds.some(id => id == null)) {
                setError("Some songs are missing required ID information");
                return;
            }

            console.log("Creating playlist with:", {
                name: playlistName,
                description,
                is_public: isPublic,
                song_ids: songIds
            });

            const storedUser = localStorage.getItem("user");
            console.log("User auth check before create:", {
                userInState: !!storedUser,
                tokenExists: !!localStorage.getItem("token"),
                cookies: document.cookie
            });

            // Extract user ID from stored user
            const userData = storedUser ? JSON.parse(storedUser) : null;
            console.log("User data:", userData);
            if (!userData || !userData.userId) {
                throw new Error("User ID not found in localStorage");
            }

            // Create playlist with songs in a single request
            const token = localStorage.getItem("token");
            const headers: HeadersInit = {
                "Content-Type": "application/json",
            };

            // Add Authorization header if token exists
            if (token) {
                headers["Authorization"] = `Bearer ${token}`;
            }

            const playlistResponse = await fetch("/api/playlists", {
                method: "POST",
                headers,
                credentials: "include", // Include cookies in the request
                body: JSON.stringify({
                    name: playlistName,
                    description,
                    is_public: isPublic,
                    song_ids: songIds,
                    userId: userData.userId // Add the user ID to the request body
                }),
            });

            if (!playlistResponse.ok) {
                const errorText = await playlistResponse.text();
                console.error("Server error response:", errorText);
                throw new Error(`Failed to create playlist: ${playlistResponse.status}`);
            }

            const playlistData = await playlistResponse.json();
            console.log("Playlist created successfully:", playlistData);

            // Navigate to the newly created playlist
            navigate(`/playlist/${playlistData._id}`);
        } catch (error) {
            console.error("Error creating playlist:", error);
            setError("Failed to create playlist. Please try again.");
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="create-playlist-page">
            <div className="create-playlist-container">
                <h2>Create New Playlist</h2>

                {error && <div className="error-message">{error}</div>}

                <div className="playlist-form">
                    <div className="form-group">
                        <label htmlFor="playlist-name">Playlist Name</label>
                        <input
                            id="playlist-name"
                            type="text"
                            value={playlistName}
                            onChange={(e) => setPlaylistName(e.target.value)}
                            placeholder="Enter playlist name"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="playlist-description">Description (optional)</label>
                        <textarea
                            id="playlist-description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter a description for your playlist"
                            rows={3}
                        />
                    </div>

                    <div className="form-group checkbox-group">
                        <label htmlFor="playlist-public">
                            <input
                                id="playlist-public"
                                type="checkbox"
                                checked={isPublic}
                                onChange={(e) => setIsPublic(e.target.checked)}
                            />
                            Make this playlist public
                        </label>
                    </div>
                </div>

                <div className="song-search-section">
                    <h3>Add Songs to Your Playlist</h3>
                    <div className="song-search-container">
                        <ReactSearchAutocomplete
                            key={`search-${selectedSongs.length}`}
                            items={songs}
                            onSearch={handleOnSearch}
                            onSelect={handleOnSelect}
                            formatResult={formatResult}
                            showItemsOnFocus={true}
                            placeholder="Search for songs to add"
                            maxResults={15}
                            autoFocus={false}
                            showIcon={true}
                            showClear={true}
                            onClear={() => console.log("Search cleared")}
                            fuseOptions={{
                                keys: [
                                    "name",
                                    "artist_english_name",
                                ],
                                threshold: 0.3,
                            }}
                            styling={{
                                height: "40px",
                                border: "1px solid #dfe1e5",
                                borderRadius: "24px",
                                backgroundColor: "white",
                                boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                                hoverBackgroundColor: "#eee",
                                fontSize: "16px",
                                iconColor: "grey",
                                lineColor: "rgb(232, 234, 237)",
                                placeholderColor: "grey",
                                clearIconMargin: "3px 14px 0 0",
                            }}
                        />
                    </div>

                    <div className="selected-songs-container">
                        <h4>Selected Songs ({selectedSongs.length})</h4>
                        {selectedSongs.length === 0 ? (
                            <p className="no-songs-message">No songs added yet. Use the search bar above to find songs.</p>
                        ) : (
                            <div className="selected-songs-list">
                                {selectedSongs.map((song, index) => {
                                    const songId = song.melon_song_id || song.id;
                                    return (
                                        <div key={`song-${index}-${songId}`} className="selected-song-item">
                                            <span className="song-number">{index + 1}</span>
                                            <div className="song-display-wrapper">
                                                <SongDisplayList
                                                    key={songId}
                                                    song={{ song_id: songId || 0 }}
                                                    name={song.name}
                                                    picture_url={song.picture_url || "/dubuFace.jpg"}
                                                    year=""
                                                    artist={[song.artist_english_name]}
                                                    artist_ids={song.artist_ids && song.artist_ids.length > 0 ? song.artist_ids : [0]}
                                                />
                                            </div>
                                            <button
                                                className="remove-song-button-new"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveSong(index);
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>

                <div className="form-actions">
                    <button
                        className="cancel-button"
                        onClick={() => navigate('/profile')}
                    >
                        Cancel
                    </button>
                    <button
                        className="create-button"
                        onClick={handleCreatePlaylist}
                        disabled={isCreating || !playlistName.trim() || selectedSongs.length === 0}
                    >
                        {isCreating ? "Creating..." : "Create Playlist"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreatePlaylistPage;
