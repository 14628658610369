import Carousel from "react-bootstrap/Carousel";
import { SetStateAction, useState } from "react";
import { Link } from "react-router-dom";

import Image1 from "@images/20250307_jennie.jpeg";
import Image2 from "@images/20250307_hearts2hearts.jpeg";


const data = [
  {
    image: Image1,
    caption: "jennie",
    description: "but have you ever met?",
    album: 24058745,
  },
  {
    image: Image2,
    caption: "hearts2hearts",
    description: "i love the way, you love the chase",
    album: 24044143,
  },
];

function HomeCarousel() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: SetStateAction<number>) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="carousel">
      {data.map((slide, idx) => {
        return (
          <Carousel.Item key={idx}
            style={{
              backgroundImage: `url(${slide.image})`,
              backgroundColor: "#ccc",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}>
            <img
              className="carousel-home"
              loading="lazy"
              src={slide.image}
              alt="banner of the latest kpop releases"
              style={{ visibility: "hidden" }}
            />
            <Link to={`/album/${slide.album}`}>
              <Carousel.Caption>
                <h3 style={{ fontSize: "300%" }}>{slide.caption}</h3>
                <p>{slide.description}</p>
              </Carousel.Caption>
            </Link>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
export default HomeCarousel;
