import React, { useState, useEffect, useRef } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useNavigate, useLocation } from "react-router-dom";
import { encodeId } from "../HelperMethods";
import PhotoMethods from "../HelperMethods";
import "./Navbar.css";

const SONG = "SONG";
const ARTIST = "ARTIST";
const ALBUM = "ALBUM";
const IDOL = "IDOL";

function SearchBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [updatedResults, setUpdatedResults] = useState<any[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const typingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [searchData, setSearchData] = useState<{
    artists: any[];
    albums: any[];
    songs: any[];
  }>({ artists: [], albums: [], songs: [] });

  const formatResult = (item: any) => (
    <div className="search-results-dropdown">
      <img
        className="search-photo"
        src={PhotoMethods(item.picture_url)}
        style={{ maxWidth: "50px", paddingRight: "1em" }}
        loading="lazy"
        onError={(e) => {
          e.currentTarget.src = "/dubuFace.jpg";
          e.currentTarget.onerror = null;
        }}
      />
      <span className="search-result">
        <h6 className="search-result-type">
          {item.type}{" "}
          {(item.type.includes(ALBUM) || item.type.includes(SONG)) &&
            " BY " + item?.artist_english_name}
        </h6>
        <div className="search-name">{item.name}</div>
      </span>
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [artistsRes, albumsRes, songsRes] = await Promise.all([
          fetch("/api/artists/search"),
          fetch("/api/albums/search"),
          fetch("/api/songs/search"),
        ]);

        const [artists, albums, songs] = await Promise.all([
          artistsRes.json(),
          albumsRes.json(),
          songsRes.json(),
        ]);

        setSearchData({ artists, albums, songs });
      } catch (error) {
        console.error("Error fetching search data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && searchQuery.trim() !== "" && !isHovered) {
        if (typingTimeout.current) {
          clearTimeout(typingTimeout.current); // Clear any ongoing timeout
        }

        // Delay navigation slightly to prevent multiple URL updates
        typingTimeout.current = setTimeout(() => {
          navigate(`/search?query=${searchQuery}`, {
            state: { results: updatedResults },
          });
        }, 300); // Debounce delay
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [updatedResults]);


  const handleOnSearch = (query: string, results: any[]) => {
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current); // Clear previous debounce
    }

    // Debounce search updates to avoid submitting on every keystroke
    typingTimeout.current = setTimeout(() => {
      setSearchQuery(query);
      setUpdatedResults(results);
    }, 300);
  };

  const handleOnHover = (isHovering: boolean) => {
    setIsHovered(isHovering);
  };

  const handleOnSelect = (item: any) => {
    const pathSegment = window.location.pathname.split("/")[1];
    var type;

    // the item selected
    if (item.type.includes(ARTIST) || item.type.includes(IDOL)) {
      type = 'artist';
      navigate(`/${type}/${encodeId(item.id)}`, {
        state: { id: item.id },
      });
    } else if (item.type.includes(ALBUM)) {
      type = 'album';
      navigate(`/${type}/${encodeId(item.id)}`, {
        state: { id: item.id },
      });
    } else if (item.type.includes(SONG)) {
      type = 'song';
      navigate(`/${type}/${encodeId(item.id)}`, {
        state: { song_id: item.id },
      });
    }

    if (pathSegment == type) {
      window.location.reload();
    }
  };

  const handleSearchClick = () => {
    if (searchQuery.trim() !== "") {
      navigate(`/search?query=${searchQuery}`, { state: { results: updatedResults } });
    }
  };

  const toggleSearchBar = () => {
    // If on mobile, we'll use the original behavior with full-width expansion
    if (window.innerWidth <= 1042) {
      setIsExpanded(true); // Just set to true, don't toggle
    } else {
      // Desktop behavior - toggle the search bar
      setIsExpanded(!isExpanded);
    }
  };

  // Handle clicks outside the search bar to collapse it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchBarRef]);

  // Reset search bar on route change
  useEffect(() => {
    setIsExpanded(false);
    setIsHovered(false);
  }, [location.pathname]);

  // Helper function to check if we're on mobile
  const isMobile = () => window.innerWidth <= 1042;

  // Add resize event listener to handle window size changes
  useEffect(() => {
    const handleResize = () => {
      // If resizing to mobile while expanded, ensure proper classes
      if (isExpanded && isMobile()) {
        // Make sure we're using the correct mobile styles
        const searchWrapper = document.querySelector('.search-bar-wrapper');
        if (searchWrapper) {
          searchWrapper.classList.remove('expanded-search');
          searchWrapper.classList.add('expanded');
        }
      } else if (isExpanded && !isMobile()) {
        // Make sure we're using the correct desktop styles
        const searchWrapper = document.querySelector('.search-bar-wrapper');
        if (searchWrapper) {
          searchWrapper.classList.remove('expanded');
          searchWrapper.classList.add('expanded-search');
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isExpanded]);

  return (
    <div className="search-bar-container" ref={searchBarRef}>
      {!isExpanded ? (
        <i className="fa fa-search search-icon-collapsed" onClick={toggleSearchBar}></i>
      ) : (
        <>
          {isMobile() && <div className="search-overlay" onClick={() => setIsExpanded(false)} />}
          <div className="search-bar-header">
            {searchData.artists && searchData.songs && searchData.albums && (
              <div className={`search-bar-wrapper ${isMobile() ? "expanded" : "expanded-search"}`}>
                <ReactSearchAutocomplete
                  items={[
                    ...searchData.artists,
                    ...searchData.songs,
                    ...searchData.albums,
                  ]}
                  fuseOptions={{
                    keys: [
                      { name: "name", weight: 0.7 },
                      { name: "artist_english_name", weight: 0.3 },
                    ],
                    threshold: 0.3,
                  }}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  onHover={handleOnHover}
                  formatResult={formatResult}
                  autoFocus
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default SearchBar;
