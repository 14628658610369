import { Link } from "react-router-dom";
import { TruncateName } from "../HelperMethods";
import { encodeId } from "../HelperMethods";
import React from "react";
import { SongDisplayProps } from "../../types/PlaylistTypes";

interface SongDisplayListProps {
  song: SongDisplayProps;
  name: string;
  picture_url: string;
  year: Date | number | string;
  artist?: string[];
  artist_ids?: number[];
}

const SongDisplayList: React.FC<SongDisplayListProps> = ({
  song,
  name,
  picture_url,
  year,
  artist,
  artist_ids,
}) => {
  const song_id = song.song_id;

  var release = "";
  if (year) {
    if (typeof year == "object") {
      year.setHours(year.getHours() + 5);
    }
    release = year.toString().slice(0, 10).replaceAll("-", ".");
  }

  // if (song && song?.artist_ids?.length > 0) {
  // }

  return (
    <div className="album-list">
      <div className="song-metadata">
        {release && artist && artist_ids && artist_ids.length == 1 && (
          <h6>
            {release} ·{" "}
            <Link
              key={artist_ids.toString()}
              state={{ id: artist_ids }}
              onClick={() => {
                window.location.href = `/artist/${encodeId(artist_ids[0])}`;
              }}
              to={""}
            >
              {TruncateName(artist[0], 30)}
            </Link>
          </h6>
        )}
        {release && artist && artist_ids && artist_ids.length > 1 && (
          <h6>
            {release} · {TruncateName(artist.join(", "), 30)}
          </h6>
        )}
        {release && !artist && <h6>{release}</h6>}
      </div>
      <Link
        key={song_id}
        to={`/song/${encodeId(song_id)}`}
        state={{ song_id: song_id, album_id: song.melon_album_id }}
      >
        {song.is_title && (
          <p>
            ✰ <strong>{TruncateName(name)}</strong>
          </p>
        )}
        {!song.is_title && (
          <p>
            <strong>{TruncateName(name)}</strong>
          </p>
        )}
        <div
          className="album-display-photo-wrapper"
          style={{
            backgroundColor: "#ccc",
            // width: "100%",
            // height: "100%",
            backgroundImage: `url(${picture_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <img
            className="album-display-photo"
            src={picture_url}
            alt={"album photo of song " + song.english_name}
            onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
              event.currentTarget.src = "/dubuFace260.jpg";
              event.currentTarget.onerror = null;
            }}
            style={{ visibility: "hidden" }}
          />
        </div>
      </Link>
    </div>
  );
};

export default SongDisplayList;
