import { Helmet } from "react-helmet-async";
import PageNotFoundPage from "./PageNotFoundPage";
import SongDisplayList from "../components/ViewAll/SongDisplayList";
import { useState, useEffect } from "react";

const OnThisDaySongs = () => {
  const [songs, setSongs] = useState<SongAllDetails[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSongs = async () => {
      const response = await fetch("/api/songs/onthisday");
      const json = await response.json();

      if (response.ok) {
        setSongs(json);
      } else {
        setError(response.status + " - " + json.error);
      }
    };
    fetchSongs();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;

  return (
    // <div className="padding">
    <div className="on-this-day-details">
      {/* <Helmet>
          <title>on this day - dubu</title>
          <meta name="keywords" content="kpop, dubu, database, kpop credits" />
          <meta name="description" content="dubu on this day" />
        </Helmet> */}

      <div className="album-header">
        <h4>on this day:</h4>
        <h4>{new Date().toLocaleString("en-us", {
          month: "short",
          day: "numeric"
        }).toLowerCase()}
        </h4>

      </div>
      {/* <br></br> */}
      <div className="display-list">
        {songs &&
          songs
            // .slice(0, 10)
            .map((song) => (
              <SongDisplayList
                key={song.melon_song_id + song.melon_artist_id}
                song={song}
                name={song.english_name}
                picture_url={song.picture_url}
                year={new Date(song.release_date).getFullYear()}
                artist={song.artist_english_names}
                artist_ids={song.melon_artist_ids}
              />
            ))}
      </div>
    </div>
  );
};

export default OnThisDaySongs;
