import React, { useEffect, useState } from "react";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import "./Profile.css";
import { Playlist } from "../../types/PlaylistTypes";

const ProfilePage: React.FC = () => {
    const [user, setUser] = useState<any>(null);
    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Load user session on page load
        const storedUser = localStorage.getItem("user");

        if (storedUser) {
            setUser(JSON.parse(storedUser));
            fetchPlaylists();
        } else {
            // Redirect to login if not logged in
            navigate("/login");
        }
    }, [navigate]);

    const fetchPlaylists = async () => {
        setIsLoading(true);
        try {
            // Check if user data exists
            const storedUser = localStorage.getItem("user");
            console.log("User data exists:", !!storedUser);
            if (storedUser) {
                const userData = JSON.parse(storedUser);
                console.log("Full user data:", userData);
                console.log("User ID:", userData.userId);
                console.log("Dubu user:", userData.dubu_user);
            }

            // Log what cookies might be available
            console.log("Document cookies:", document.cookie);

            // Prepare headers
            const headers: HeadersInit = {
                "Content-Type": "application/json",
            };

            console.log("Request headers:", headers);

            const response = await fetch("/api/playlists/mine", {
                method: "GET",
                headers,
                credentials: "include", // Include cookies in the request
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error("Error response details:", {
                    status: response.status,
                    statusText: response.statusText,
                    headers: Object.fromEntries(response.headers.entries()),
                    body: errorText
                });
                throw new Error(`Failed to fetch playlists: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            setPlaylists(data);
        } catch (error) {
            console.error("Error fetching playlists:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = async () => {
        try {
            // Clear frontend stored data first
            setUser(null);
            localStorage.removeItem("token");
            localStorage.removeItem("user");

            // Dispatch custom event for login state change
            window.dispatchEvent(new Event('loginStateChange'));

            // Call backend to remove the token cookie
            const response = await fetch("/api/auth/logout", {
                method: "POST",
                credentials: "include", // Include cookies in the request
            });

            if (!response.ok) {
                throw new Error(`Logout failed with status: ${response.status}`);
            }

            console.log("Logged out successfully");

            // Call Google logout last
            try {
                googleLogout();
            } catch (googleError) {
                console.log("Google logout error (non-critical):", googleError);
            }

            // Navigate back to login page
            navigate("/login");

        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    // Add a checkAuth function to verify authentication status
    const checkAuth = async () => {
        try {
            const token = localStorage.getItem("token");
            const headers: HeadersInit = {
                "Content-Type": "application/json",
            };

            if (token) {
                headers["Authorization"] = `Bearer ${token}`;
            }

            const response = await fetch("/api/auth/check", {
                method: "GET",
                headers,
                credentials: "include",
            });

            const data = await response.json();
            console.log("Auth check response:", data);

            if (response.ok) {
                console.log("Authentication valid, user:", data.user);
                alert("Authentication valid!");
                // Try fetching playlists again
                fetchPlaylists();
            } else {
                console.error("Authentication failed:", data);
                alert("Authentication failed: " + data.message);
            }
        } catch (error) {
            console.error("Error checking auth:", error);
            alert("Error checking authentication");
        }
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className="profile-page">
            <div className="profile-content">
                <div className="profile-container">
                    <h3>welcome, {user.given_name}</h3>
                    <img src={user.picture} alt="Profile" className="profile-image" />
                    <p>username: {user.dubu_user}</p>
                    <button onClick={checkAuth} className="check-auth-button">
                        check auth
                    </button>
                    <button onClick={handleLogout} className="logout-button">
                        logout
                    </button>
                </div>

                <div className="playlist-container-profile">
                    <div className="playlist-header">
                        <h3>my playlists</h3>
                        <button
                            className="create-playlist-button"
                            onClick={() => navigate('/create-playlist')}
                        >
                            create new
                        </button>
                    </div>
                    {isLoading ? (
                        <p>Loading playlists...</p>
                    ) : playlists.length === 0 ? (
                        <p>No playlists found. Create one to get started!</p>
                    ) : (
                        <div className="playlist-list">
                            {playlists.map((playlist) => (
                                <div key={playlist._id} className="playlist-item" onClick={() => navigate(`/playlist/${playlist._id}`)}>
                                    <h4>{playlist.name}</h4>
                                    {playlist.description && <p>{playlist.description}</p>}
                                    <p className="playlist-visibility">{playlist.is_public ? "Public" : "Private"}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
