import { SyntheticEvent, useEffect, useState } from "react";
import MembershipDetails from "../MembershipDetails";
import PhotoMethods from "../HelperMethods";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const ArtistPageLeftCard: React.FC<ArtistDetailsProps> = ({
  artist,
  memberships,
}) => {
  const [members, setMembers] = useState<Membership[] | null>(null);
  const tooltip = <Tooltip id="tooltip">copy artist link</Tooltip>;
  const copied_tooltip = <Tooltip id="tooltip">copied artist url!</Tooltip>;
  const [showCopiedTooltip, setShowCopiedTooltip] = useState<boolean>(false);
  const SUBUNIT = "subunit";
  const PRODUCTION = "production";

  const groups = memberships ? memberships.filter((m) => m.active == true && m.group_type != PRODUCTION) : [];
  const past_groups = memberships ? memberships.filter((m) => m.active == false && m.group_type != PRODUCTION) : [];
  const production_groups = memberships ? memberships.filter((m) => m.group_type == PRODUCTION) : [];

  const copyLink = () => {
    // Your copy link logic here
    navigator.clipboard.writeText(window.location.href);
    setShowCopiedTooltip(true);
    setTimeout(() => {
      setShowCopiedTooltip(false);
    }, 1000); // Tooltip will disappear after 1 second
  };

  useEffect(() => {
    const fetchMembers = async () => {
      const response = await fetch(
        "/api/memberships/group/" + artist.melon_artist_id
      );
      const json = await response.json();
      // process json for multiple rows before setting members
      if (response.ok) {
        setMembers(json);
      }
    };

    fetchMembers();
  }, []);

  return (
    <>
      <div className="artist-page-left-tile">
        <div className="artist-page-name">
          <p>
            {artist.melon_artist_id != -1 && (
              <span className="main-text-english">
                {artist.english_name} {" "}
              </span>
            )}
            <span className="main-text-korean">
              {artist.korean_name}
            </span>
          </p>

          <h4 className="main-text">
            {artist.group_type == PRODUCTION && "production "}
            {artist.group_type == SUBUNIT && "subunit "}
            {artist.is_group && "group"} {!artist.is_group && "solo"}
            {artist.debut_date && " • " + artist.debut_date + " debut"}
          </h4>
        </div>

        {artist &&
          !artist.is_idol &&
          <div className="incomplete-warning">
            <p><i>
              warning: artist data may be incomplete.
            </i>
            </p>
          </div>
        }

        {artist && artist.spotify_artist_id && (
          <div className="spotify-details">
            <iframe
              className="spotify-artist-embed"
              style={{ "borderRadius": "12px" }}
              src={
                "https://open.spotify.com/embed/artist/" +
                artist.spotify_artist_id +
                "?utm_source=generator"
              }
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        )}

        <div className="fields-all">
          {members && members.filter(
            (m) => m.active == true && !m.group_type
          ).length > 0 && (
              <MembershipDetails
                memberships={members.filter(
                  (m) => m.active == true && !m.group_type
                )}
                type="members"
              ></MembershipDetails>
            )}
          {members && members.filter(
            (m) => m.active == false && !m.group_type
          ).length > 0 && (
              <MembershipDetails
                memberships={members.filter(
                  (m) => m.active == false && !m.group_type
                )}
                type="past members"
              ></MembershipDetails>
            )}

          {memberships && groups.length > 0 && (
            <MembershipDetails
              memberships={groups}
              type="groups"
            ></MembershipDetails>
          )}
          {memberships && past_groups.length > 0 && (
            <MembershipDetails
              memberships={past_groups}
              type="past groups"
            ></MembershipDetails>
          )}
          {memberships && production_groups.length > 0 && (
            <MembershipDetails
              memberships={production_groups}
              type="production groups"
            ></MembershipDetails>
          )}
          {members &&
            members.filter((m) => m.group_type == SUBUNIT).length > 0 && (
              <MembershipDetails
                memberships={members.filter((m) => m.group_type == SUBUNIT)}
                type="subunits"
              ></MembershipDetails>
            )}
        </div>
      </div>
    </>
  );
};

export default ArtistPageLeftCard;
