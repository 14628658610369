import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SongDisplayList from "../ViewAll/SongDisplayList";
import "./PlaylistDetails.css";
import { encodeId, TruncateName } from "../HelperMethods";
import {
    Playlist,
    PlaylistSong,
    EnrichedSong,
    PlaylistDetailsProps
} from "../../types/PlaylistTypes";
import TextCardComponent from "../TextCardComponent";

const PlaylistDetails: React.FC<PlaylistDetailsProps> = ({
    playlistId
}) => {
    const [playlist, setPlaylist] = useState<Playlist | null>(null);
    const [songs, setSongs] = useState<PlaylistSong[]>([]);
    const [enrichedSongs, setEnrichedSongs] = useState<EnrichedSong[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlaylistDetails = async () => {
            setIsLoading(true);
            setError(null);

            try {
                // Fetch the playlist info
                const playlistResponse = await fetch(`/api/playlists/${playlistId}`);

                if (!playlistResponse.ok) {
                    throw new Error(`Failed to fetch playlist: ${playlistResponse.status}`);
                }

                const playlistData = await playlistResponse.json();
                setPlaylist(playlistData);

                // Fetch the songs in the playlist
                const songsResponse = await fetch(`/api/playlists/${playlistId}/songs`);

                if (!songsResponse.ok) {
                    throw new Error(`Failed to fetch songs: ${songsResponse.status}`);
                }

                const songsData = await songsResponse.json();
                setSongs(songsData);

                // Get detailed song info for each song
                const enrichedSongsPromises = songsData.map(async (song: PlaylistSong) => {
                    try {
                        const songResponse = await fetch(`/api/songs/details/${song.melon_song_id}`);
                        if (!songResponse.ok) {
                            console.error(`Failed to fetch song details for ${song.melon_song_id}`);
                            return song;
                        }
                        const songData = await songResponse.json();
                        return {
                            ...song,
                            ...songData,
                        };
                    } catch (err) {
                        console.error(`Error fetching song details for ${song.melon_song_id}:`, err);
                        return song;
                    }
                });

                const enrichedSongsData = await Promise.all(enrichedSongsPromises);
                setEnrichedSongs(enrichedSongsData);
            } catch (err) {
                console.error("Error fetching playlist details:", err);
                setError(err instanceof Error ? err.message : String(err));
            } finally {
                setIsLoading(false);
            }
        };

        if (playlistId) {
            fetchPlaylistDetails();
        }
    }, [playlistId]);

    const handleSongClick = (songId: number) => {
        navigate(`/song/${encodeId(songId)}`);
    };

    const formatDate = (dateString: string) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    if (isLoading) {
        return <div className="playlist-details loading">Loading playlist...</div>;
    }

    if (error) {
        return <div className="playlist-details error">Error: {error}</div>;
    }

    if (!playlist) {
        return <div className="playlist-details not-found">Playlist not found</div>;
    }

    return (
        <div className="playlist-details">
            <div className="playlist-header">
                <div className="main-text">{playlist.name}</div>
                <span className={`visibility-badge ${playlist.is_public ? 'public' : 'private'}`}>
                    {playlist.is_public ? 'Public' : 'Private'}
                </span>

                <div className="playlist-meta">
                </div>
            </div>
            <div className="playlist-container">
                <div className="playlist-container-left">
                    {playlist.description && <TextCardComponent description={playlist.description} />}
                    <div className="songs-container">
                        {enrichedSongs.length === 0 ? (
                            <p>No songs in this playlist yet.</p>
                        ) : (
                            <div className="songs-list">
                                {enrichedSongs.map((song, index) => {
                                    return (
                                        <div
                                            key={`${song.melon_song_id}-${index}`}
                                            className="song-item"
                                            onClick={() => handleSongClick(song.melon_song_id)}
                                        >
                                            <span className="song-number">{index + 1}</span>
                                            <div className="song-display-wrapper">
                                                <div
                                                    className="album-display-photo-wrapper"
                                                    style={{
                                                        backgroundColor: "#ccc",
                                                        backgroundImage: `url(${song.picture_url})`,
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center",
                                                        width: "60px",
                                                        height: "60px",
                                                        marginRight: "1rem"
                                                    }}
                                                >
                                                    <img
                                                        className="album-display-photo"
                                                        src={song.picture_url}
                                                        alt={"album photo of song " + song.english_name}
                                                        onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
                                                            event.currentTarget.src = "/dubuFace260.jpg";
                                                            event.currentTarget.onerror = null;
                                                        }}
                                                        style={{ visibility: "hidden" }}
                                                    />
                                                </div>
                                                <div className="song-playlist-details">
                                                    {song.artist_ids && song.artist_ids.length == 1 && (
                                                        <h6>
                                                            <Link
                                                                key={song.artist_ids.toString()}
                                                                state={{ id: song.artist_ids }}
                                                                onClick={() => {
                                                                    window.location.href = `/artist/${encodeId(song.artist_ids[0])}`;
                                                                }}
                                                                to={""}
                                                            >
                                                                {TruncateName(song.artist_names[0], 30)}
                                                            </Link>
                                                        </h6>
                                                    )}
                                                    {song.artist_ids && song.artist_ids.length > 1 && (
                                                        <h6>
                                                            {TruncateName(song.artist_names.join(", "), 30)}
                                                        </h6>
                                                    )}
                                                    <Link
                                                        key={song.melon_song_id}
                                                        to={`/song/${encodeId(song.melon_song_id)}`}
                                                        state={{ song_id: song.melon_song_id, album_id: song.melon_album_id }}
                                                    >
                                                        {song.is_title && (
                                                            <p>
                                                                ✰ <strong>{song.english_name}</strong>
                                                            </p>
                                                        )}
                                                        {!song.is_title && (
                                                            <p>
                                                                <strong>{song.english_name}</strong>
                                                            </p>
                                                        )} </Link>
                                                </div>
                                            </div>
                                            {/* <div className="song-info">
                                                <h6>
                                                    <Link
                                                        key={song.album_english_name}
                                                        state={{ id: song.album_english_name }}
                                                        onClick={() => {
                                                            window.location.href = `/album/${encodeId(song.album_english_name)}`;
                                                        }}
                                                        to={""}
                                                    >
                                                        {TruncateName(song.album_english_name, 30)}
                                                    </Link>
                                                </h6>

                                            </div> */}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                </div>
                <div className="playlist-container-right">
                    <img className="dubu-music-image" src="/dubu_music.png"
                        alt="dubu"
                        style={{
                            width: "15em",
                            height: "15em",
                            objectFit: "cover",
                            backgroundColor: "transparent",
                            boxShadow: "0 auto"
                        }}
                    />

                    <TextCardComponent description={`Tags here`} />

                    <TextCardComponent description={`Created: ${formatDate(playlist.created_at)} Updated: ${formatDate(playlist.updated_at)}`} />

                </div>
            </div>
        </div>
    );
};

export default PlaylistDetails;
