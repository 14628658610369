import React, { useState, useEffect, useRef } from "react";
import SongDisplayList from "../ViewAll/SongDisplayList";
import "./PlaylistDetails.css";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import {
    Playlist,
    PlaylistSong,
    EnrichedSong,
    Song
} from "../../types/PlaylistTypes";

interface EditPlaylistDetailsProps {
    playlistId: string;
    onSaveComplete: () => void;
    registerSaveFunction: (saveFunction: () => Promise<boolean>) => void;
}

const EditPlaylistDetails: React.FC<EditPlaylistDetailsProps> = ({
    playlistId,
    onSaveComplete,
    registerSaveFunction
}) => {
    const [playlist, setPlaylist] = useState<Playlist | null>(null);
    const [songs, setSongs] = useState<PlaylistSong[]>([]);
    const [enrichedSongs, setEnrichedSongs] = useState<EnrichedSong[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchSongs, setSearchSongs] = useState<PlaylistSong[]>([]);
    const [searchResults, setSearchResults] = useState<EnrichedSong[]>([]);
    const [selectedSongs, setSelectedSongs] = useState<PlaylistSong[]>([]);
    const [playlistName, setPlaylistName] = useState('');
    const [description, setDescription] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [enrichedSelectedSongs, setEnrichedSelectedSongs] = useState<EnrichedSong[]>([]);

    // Use ref to track if we've already registered the save function
    const hasRegisteredSaveFunction = useRef(false);
    // Store the function ref to update it without re-registering
    const saveFunction = useRef<() => Promise<boolean>>();

    // This function will be called when Save is clicked
    const handleSaveChanges = async (): Promise<boolean> => {
        if (!playlist) return false;

        setIsSaving(true);
        setError(null);

        try {
            // Update playlist details
            const payload = {
                name: playlistName,
                description: description,
                is_public: isPublic
            };

            const updateResponse = await fetch(`/api/playlists/${playlistId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!updateResponse.ok) {
                const errorText = await updateResponse.text();
                throw new Error(`Failed to update playlist: ${updateResponse.status} - ${errorText}`);
            }

            const updatedPlaylist = await updateResponse.json();

            // Get the current songs in the playlist
            const songsResponse = await fetch(`/api/playlists/${playlistId}/songs`);
            if (!songsResponse.ok) {
                throw new Error(`Failed to fetch playlist songs: ${songsResponse.status}`);
            }

            const currentSongs = await songsResponse.json();

            // First, remove all existing songs
            for (const song of currentSongs) {
                await fetch(`/api/playlists/${playlistId}/songs/${song.melon_song_id}`, {
                    method: 'DELETE',
                });
            }

            // Then add all songs in the exact order they appear in the UI
            for (let i = 0; i < selectedSongs.length; i++) {
                const song = selectedSongs[i];
                await fetch(`/api/playlists/${playlistId}/songs`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        song_id: song.melon_song_id,
                        position: i + 1 // Start positions at 1 to match UI numbering
                    }),
                });
            }

            // Don't update any state, let the parent component handle refreshing
            if (onSaveComplete) {
                onSaveComplete();
            }

            return true;
        } catch (err) {
            console.error("Error saving playlist changes:", err);
            setError(err instanceof Error ? err.message : String(err));
            return false;
        } finally {
            setIsSaving(false);
        }
    };

    // Store the latest version of the function in the ref
    useEffect(() => {
        saveFunction.current = handleSaveChanges;
    }, [playlist, playlistName, description, isPublic, selectedSongs, onSaveComplete, playlistId]);

    // Register the save function only once when the component mounts
    useEffect(() => {
        if (registerSaveFunction && !hasRegisteredSaveFunction.current) {
            // Create a stable function that calls the latest version stored in the ref
            const stableFunction = async () => {
                if (saveFunction.current) {
                    return saveFunction.current();
                }
                return false;
            };

            registerSaveFunction(stableFunction);
            hasRegisteredSaveFunction.current = true;
        }
    }, [registerSaveFunction]);

    useEffect(() => {
        const fetchPlaylistDetails = async () => {
            setIsLoading(true);
            setError(null);

            try {
                // Fetch the playlist info
                const playlistResponse = await fetch(`/api/playlists/${playlistId}`);

                if (!playlistResponse.ok) {
                    throw new Error(`Failed to fetch playlist: ${playlistResponse.status}`);
                }

                const playlistData = await playlistResponse.json();
                setPlaylist(playlistData);
                setPlaylistName(playlistData.name);
                setDescription(playlistData.description || '');
                setIsPublic(playlistData.is_public);

                // Fetch the songs in the playlist
                const songsResponse = await fetch(`/api/playlists/${playlistId}/songs`);

                if (!songsResponse.ok) {
                    throw new Error(`Failed to fetch songs: ${songsResponse.status}`);
                }

                const songsData = await songsResponse.json();
                setSongs(songsData);
                setSelectedSongs(songsData);

                // Get detailed song info for each song
                const enrichedSongsPromises = songsData.map(async (song: PlaylistSong) => {
                    try {
                        const songResponse = await fetch(`/api/songs/details/${song.melon_song_id}`);
                        if (!songResponse.ok) {
                            console.error(`Failed to fetch song details for ${song.melon_song_id}`);
                            return song;
                        }
                        const songData = await songResponse.json();
                        return {
                            ...song,
                            ...songData,
                        };
                    } catch (err) {
                        console.error(`Error fetching song details for ${song.melon_song_id}:`, err);
                        return song;
                    }
                });

                const enrichedSongsData = await Promise.all(enrichedSongsPromises);
                setEnrichedSongs(enrichedSongsData);
            } catch (err) {
                console.error("Error fetching playlist details:", err);
                setError(err instanceof Error ? err.message : String(err));
            } finally {
                setIsLoading(false);
            }
        };

        if (playlistId) {
            fetchPlaylistDetails();
        }
    }, [playlistId]);

    useEffect(() => {
        const fetchSearchSongs = async () => {
            try {
                const response = await fetch('/api/songs/search');
                if (response.ok) {
                    const data = await response.json();
                    setSearchSongs(data);
                }
            } catch (error) {
                console.error("Error fetching songs for search:", error);
            }
        };

        fetchSearchSongs();
    }, []);

    const handleOnSearch = (string: string, results: PlaylistSong[]) => {
        if (!string.trim()) {
            setSearchResults([]);
            return;
        }

        // Convert the results to EnrichedSong type
        const enrichedResults = results.slice(0, 10).map(song => ({
            ...song,
            release_date: song.release_date?.toString()
        })) as EnrichedSong[];

        setSearchResults(enrichedResults);
    };

    const handleOnSelect = (item: PlaylistSong) => {
        // Check if the song is already in the playlist
        const songId = item.melon_song_id || item.id;
        if (!songId) {
            console.error('Invalid song object:', item);
            return;
        }

        if (!selectedSongs.some(song => song.melon_song_id === songId)) {
            // Ensure we're using melon_song_id consistently
            const newSong = {
                ...item,
                melon_song_id: songId,
                id: undefined // Remove the id field to avoid confusion
            };
            // Add new song at the end of the array
            setSelectedSongs(prevSongs => [...prevSongs, newSong]);
        }
    };

    const removeSong = (songId: number) => {
        setSelectedSongs(prevSongs => prevSongs.filter(song => song.melon_song_id !== songId));
    };

    const formatResult = (item: any) => {
        return (
            <div className="search-result-item">
                <img
                    src={item.picture_url || "/dubuFace.jpg"}
                    alt={item.english_name || "Song"}
                    className="search-result-image"
                />
                <div className="search-result-text">
                    <div className="search-result-name">{item.name || "Unknown Song"}</div>
                    <div className="search-result-artist">{item.artist_english_name || "Unknown Artist"}</div>
                </div>
            </div>
        );
    };

    // Add this new useEffect to enrich selected songs when they change
    useEffect(() => {
        const enrichSelectedSongs = async () => {
            if (!selectedSongs.length) {
                setEnrichedSelectedSongs([]);
                return;
            }

            const enrichedPromises = selectedSongs.map(async (song) => {
                if (!song || !song.melon_song_id) {
                    console.error('Invalid song object:', song);
                    return null;
                }

                try {
                    const songResponse = await fetch(`/api/songs/details/${song.melon_song_id}`);
                    if (!songResponse.ok) {
                        console.error(`Failed to fetch song details for ${song.melon_song_id}`);
                        return song;
                    }
                    const songData = await songResponse.json();
                    return {
                        ...song,
                        ...songData,
                    };
                } catch (err) {
                    console.error(`Error fetching song details for ${song.melon_song_id}:`, err);
                    return song;
                }
            });

            const enrichedData = (await Promise.all(enrichedPromises)).filter((song): song is EnrichedSong => song !== null);
            setEnrichedSelectedSongs(enrichedData);
        };

        enrichSelectedSongs();
    }, [selectedSongs]);

    const formatDate = (dateString: string) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    if (isLoading) {
        return <div className="playlist-details loading">Loading playlist...</div>;
    }

    if (error) {
        return <div className="playlist-details error">Error: {error}</div>;
    }

    if (!playlist) {
        return <div className="playlist-details not-found">Playlist not found</div>;
    }

    return (
        <div className="playlist-details">
            <div className="playlist-header">
                <input
                    type="text"
                    className="edit-playlist-name"
                    value={playlistName}
                    onChange={(e) => setPlaylistName(e.target.value)}
                    placeholder="Playlist Name"
                />
                <textarea
                    className="edit-playlist-description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description (optional)"
                />
                <div className="visibility-toggle">
                    <label>
                        <input
                            type="checkbox"
                            checked={isPublic}
                            onChange={(e) => setIsPublic(e.target.checked)}
                        />
                        Make this playlist public
                    </label>
                </div>
                {isSaving && <div className="saving-indicator">Saving changes...</div>}
            </div>

            <div className="songs-container">
                <div className="song-search-section">
                    <h4>Add Songs to Your Playlist</h4>
                    <div className="song-search-container">
                        <ReactSearchAutocomplete
                            items={searchSongs}
                            onSearch={handleOnSearch}
                            onSelect={handleOnSelect}
                            formatResult={formatResult}
                            showItemsOnFocus={true}
                            placeholder="Search for songs to add"
                            maxResults={10}
                            autoFocus={false}
                            fuseOptions={{
                                keys: [
                                    { name: "name", weight: 0.7 },
                                    { name: "artist_english_name", weight: 0.3 },
                                ],
                                threshold: 0.3,
                                minMatchCharLength: 2,
                                includeScore: true
                            }}
                        />
                    </div>
                </div>

                {enrichedSelectedSongs.length === 0 ? (
                    <p>No songs in this playlist yet.</p>
                ) : (
                    <div className="songs-list">
                        {enrichedSelectedSongs.map((song, index) => {
                            const enrichedSong = song as EnrichedSong;
                            return (
                                <div
                                    key={`${song.melon_song_id}-${index}`}
                                    className="song-item"
                                >
                                    <span className="song-number">{index + 1}</span>
                                    <div className="song-display-wrapper">
                                        <SongDisplayList
                                            key={song.melon_song_id}
                                            song={{
                                                song_id: song.melon_song_id,
                                                is_title: enrichedSong.is_title,
                                                melon_album_id: enrichedSong.melon_album_id
                                            }}
                                            name={song.english_name}
                                            picture_url={song.picture_url || "/dubuFace.jpg"} year={""}                                        // year={enrichedSong.release_date ? enrichedSong.release_date.toString().slice(0, 4) : ""}
                                        />
                                    </div>
                                    <div className="song-info">
                                        <div className="song-artist">{song.artist_english_name || ""}</div>
                                        {enrichedSong.album_english_name && (
                                            <div className="song-album">
                                                Album: {enrichedSong.album_english_name}
                                            </div>
                                        )}
                                        {enrichedSong.release_date && (
                                            <div className="song-release-date">
                                                Released: {formatDate(enrichedSong.release_date)}
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        className="remove-song-button"
                                        onClick={() => removeSong(song.melon_song_id)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <div className="edit-info">
                <p className="edit-instruction">Click 'Save Changes' button at the top when done</p>
            </div>
        </div>
    );
};

export default EditPlaylistDetails; 