import { Link } from "react-router-dom";
import CreditListPerSong from "./CreditListPerSong";
import React, {
  Key,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useState,
} from "react";
// import ShareIcon from "../images/arrow-up-from-bracket-solid.svg?react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { encodeId } from "./HelperMethods";


function showSongHeader(song: Song, featured: Credits[], artistinfo: ArtistInfo[], albuminfo: AlbumInfo, type: string) {
  const album_id = song.melon_album_id;

  return (<div className={"song-header" + type}>
    <h5 className="album-name-song-page">
      ♬ TRACK {song.track_order} ON{" "}
      <Link to={`/album/${encodeId(album_id)}`} state={{ id: album_id }}>
        {albuminfo.album_english_name.toUpperCase()}
      </Link>
    </h5>
    <div className="main-song-text">
      <h1 className="song-name">
        {song.is_title && "✰"}
        {song.is_explicit && "🅴 "} {song.english_name}
        {song.korean_name && <div className="korean-small-text"> {song.korean_name && " (" + song.korean_name + ")"}</div>}
      </h1>
    </div>
    <div className="artist-credits-list">
      <div className="main-performer-list">
        by{artistinfo &&
          artistinfo.map((name) => (
            <h4 className="artist-credit-link" key={name.artist_id}>
              <Link
                to={`/artist/${encodeId(name.artist_id)}`}
                state={{ id: name.artist_id }}
              >
                {name.artist_id != -1 && name.artist_english_name}
              </Link>
              {name.artist_id == -1 && name.artist_english_name}
            </h4>
          ))}
      </div>
      {featured.length > 0 && (
        <div className="featured-list">
          featuring
          {featured.map((credit) => (
            <p className="credits-list-inner" key={credit._id}>
              <Link
                to={`/artist/${encodeId(credit.artist_id)}`}
                state={{ id: credit.artist_id }}
              >
                {credit.english_name}
              </Link>
            </p>
          ))}
        </div>
      )}
    </div>
    <h5 className="album-name-song-page-mobile" style={{ "marginBottom": "0em" }}>
      ♬ TRACK {song.track_order} ON{" "}
      <Link to={`/album/${encodeId(album_id)}`} state={{ id: album_id }} className="album-link-mobile">
        {albuminfo.album_english_name.toUpperCase()}
      </Link>
    </h5>

  </div>);
}


const SongDetails: React.FC<SongDetailsProps> = ({
  song,
  credits,
  artistinfo,
  albuminfo,
}) => {
  const album_id = song.melon_album_id;
  const featured = credits.filter((c) => c.credit_type.includes("F"));
  const written = credits.filter((c) => c.credit_type.includes("L"));
  const composed = credits.filter((c) => c.credit_type.includes("C"));
  const arranged = credits.filter((c) => c.credit_type.includes("A"));
  var picture_url;
  if (albuminfo.album_picture_url) {
    picture_url = albuminfo.album_picture_url.replace(
      /\/resize\/.*/,
      "/resize/461/quality/80/optimize"
    );
  } else {
    picture_url = "";
  }
  const tooltip = <Tooltip id="tooltip">copy song link</Tooltip>;
  const copied_tooltip = <Tooltip id="tooltip">copied song url!</Tooltip>;
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const copyLink = () => {
    // Your copy link logic here
    navigator.clipboard.writeText(window.location.href);
    setShowCopiedTooltip(true);
    setTimeout(() => {
      setShowCopiedTooltip(false);
    }, 1000); // Tooltip will disappear after 1 second
  };

  return (
    <div className="song-details">
      {showSongHeader(song, featured, artistinfo, albuminfo, "")}
      <div className="song-cards">
        <div className="song-left-card">
          {/* <h5>RELEASED ON{" "}
            {albuminfo.release_date &&
              albuminfo.release_date.toString().slice(0, 10)}</h5> */}
          <div className="artist-photo-wrapper">
            <Link to={`/album/${encodeId(album_id)}`} state={{ id: album_id }}>
              <img
                className="main-photo"
                src={picture_url}
                alt={"album photo of " + albuminfo.album_english_name}
                onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
                  event.currentTarget.src = "/dubuFace.jpg";
                  event.currentTarget.onerror = null;
                }}
              />
            </Link>
          </div>

          {showSongHeader(song, featured, artistinfo, albuminfo, "-mobile")}

          <div className="all-info">
            {song.logged_at && (
              <i>
                data last updated on {song.logged_at.toString().slice(0, 10)}.
              </i>
            )}
          </div>
        </div>
        <div className="song-right-card">
          <div className="song-credits">
            {written.length > 0 && (
              <div className="artist-credit-list">
                <p style={{ "fontSize": "1.1em", "fontWeight": "600" }}>LYRICS</p><hr></hr>
                <CreditListPerSong credits={written} />
              </div>)}
            {composed.length > 0 && (
              <div className="artist-credit-list">
                <p style={{ "fontSize": "1.1em", "fontWeight": "600" }}>
                  COMPOSITION
                </p><hr></hr>
                <CreditListPerSong credits={composed} />
              </div>)}
            {arranged.length > 0 && (
              <div className="artist-credit-list">
                <p style={{ "fontSize": "1.1em", "fontWeight": "600" }}>
                  {" "}
                  ARRANGEMENT
                </p><hr></hr>
                <CreditListPerSong credits={arranged} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};

export default SongDetails;
