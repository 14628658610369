import { Link } from "react-router-dom";
import { encodeId, TruncateName } from "./HelperMethods";

const CreditListPerSong: React.FC<CreditList> = ({ credits }) => {
  return (
    <div className="credits-list">
      {credits.map((credit) => (
        <Link
          to={`/artist/${encodeId(credit.artist_id)}`}
          key={credit.english_name + credit.artist_id.toString()}
          state={{ id: credit.artist_id }}
          className="credit-icon"
        >
          {credit.english_name.length > 0 && (
            <div className="credit-english-name">
              {TruncateName(credit?.english_name, 25)}
              <br></br>
            </div>
          )}

          <img
            className="credit-photo"
            src={credit.picture_url}
            alt={
              "artist photo of " +
              credit.english_name +
              ", " +
              credit.korean_name
            }
            onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
              event.currentTarget.src = "/dubuFace.jpg";
              event.currentTarget.onerror = null;
            }}
          />
        </Link>
      ))}
    </div>
  );
};

export default CreditListPerSong;
