import React, { useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PlaylistDetails from "../../components/Playlist/PlaylistDetails";
import EditPlaylistDetails from "../../components/Playlist/EditPlaylistDetails";
import "./Playlist.css";

const ViewPlaylistPage: React.FC = () => {
    const { playlistId } = useParams<{ playlistId: string }>();
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();

    // Use useRef instead of useState to store the save function
    const saveFunctionRef = useRef<(() => Promise<boolean>) | null>(null);

    // Handler for registering the save function from the EditPlaylistDetails component
    const registerSaveFunction = useCallback((fn: () => Promise<boolean>) => {
        saveFunctionRef.current = fn;
    }, []);

    // Handler for save button click
    const handleSaveClick = async () => {
        console.log("🔘 Save button clicked");
        console.log("🔍 Save function ref exists:", !!saveFunctionRef.current);

        if (saveFunctionRef.current) {
            try {
                console.log("🧪 Calling save function...");
                const success = await saveFunctionRef.current();
                console.log("✅ Save result:", success);

                if (success) {
                    console.log("✅ Save was successful, exiting edit mode and refreshing");
                    setIsEditMode(false);
                    // Force a clean reload of the page to avoid any stale state
                    navigate(0);
                } else {
                    console.log("❌ Save was not successful");
                }
            } catch (error) {
                console.error("❌ Error during save:", error);
            }
        } else {
            console.error("❌ No save function registered");
        }
    };

    // Handler for canceling edit mode
    const handleCancelEdit = () => {
        setIsEditMode(false);
    };

    if (!playlistId) {
        return (
            <div className="view-playlist-page">
                <div className="playlist-error">
                    <h2>Error</h2>
                    <p>No playlist ID provided.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="view-playlist-page">
            <div className="edit-button-container">
                {isEditMode ? (
                    <>
                        <button
                            className="edit-button cancel-button"
                            onClick={handleCancelEdit}
                        >
                            Cancel
                        </button>
                        <button
                            className="edit-button save-mode"
                            onClick={handleSaveClick}
                        >
                            Save Changes
                        </button>
                    </>
                ) : (
                    <button
                        className="edit-button"
                        onClick={() => setIsEditMode(true)}
                    >
                        Edit Playlist
                    </button>
                )}
            </div>

            {isEditMode ? (
                <EditPlaylistDetails
                    playlistId={playlistId}
                    onSaveComplete={() => setIsEditMode(false)}
                    registerSaveFunction={registerSaveFunction}
                />
            ) : (
                <PlaylistDetails playlistId={playlistId} />
            )}
        </div>
    );
};

export default ViewPlaylistPage;
