import { useState } from "react";
import PhotoMethods from "../HelperMethods";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const ArtistPageHeader: React.FC<ArtistPageHeaderProps> = ({
  artist,
}) => {

  const tooltip = <Tooltip id="tooltip">copy artist link</Tooltip>;
  const copied_tooltip = <Tooltip id="tooltip">copied artist url!</Tooltip>;
  const [showCopiedTooltip, setShowCopiedTooltip] = useState<boolean>(false);
  const copyLink = () => {
    // Your copy link logic here
    navigator.clipboard.writeText(window.location.href);
    setShowCopiedTooltip(true);
    setTimeout(() => {
      setShowCopiedTooltip(false);
    }, 1000); // Tooltip will disappear after 1 second
  };

  return (
    <>
      <div className="artist-details">
        <div className="artist-page-photo-wrapper">
          <img
            className="artist-main-photo"
            src={PhotoMethods(artist.picture_url)}
            alt={
              "artist photo of " +
              artist.english_name +
              ", " +
              artist.korean_name
            }
            onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
              event.currentTarget.src = "/dubuFace.jpg";
              event.currentTarget.onerror = null;
            }}
          />
        </div>
        <div className="artist-photo-overlay">
        </div>

        <div className="artist-page-photo-focus">
          <img
            className="main-photo"
            src={PhotoMethods(artist.picture_url)}
            alt={
              "artist photo of " +
              artist.english_name +
              ", " +
              artist.korean_name
            }
            onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
              event.currentTarget.src = "/dubuFace.jpg";
              event.currentTarget.onerror = null;
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ArtistPageHeader;
