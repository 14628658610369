import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Artist.css";
// import { Link, useLocation } from 'react-router-dom';

// components
import ArtistPageHeader from "../../components/Artist/ArtistPageHeader";
import ArtistPageLeftCard from "../../components/Artist/ArtistPageLeftCard"
import AlbumList from "../../components/AlbumList";
import CreditDetails from "../../components/CreditDetails";
import SongDisplayList from "../../components/ViewAll/SongDisplayList";
import PageNotFoundPage from "../PageNotFoundPage";
import { decodeId } from "../../components/HelperMethods";

const ArtistPage = () => {
  const [artists, setArtist] = useState<Artist[] | null>(null);
  const [albums, setAlbums] = useState<Album[] | null>(null);
  const [credits, setCredits] = useState<Credits[] | null>(null);
  const [memberships, setMemberships] = useState<Membership[] | null>(null);
  const [titles, setTitles] = useState<Song[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { artist } = useParams();
  const artist_id = decodeId(Number(artist));

  useEffect(() => {
    const fetchArtist = async () => {
      const response = await fetch("/api/artists/" + artist_id);
      const json = await response.json();

      if (response.ok && JSON.stringify(json) !== JSON.stringify(artists)) {
        setArtist(json);
      } else if (response.status == 404 && !error) {
        setError("Artist does not exist");
      } else if (response.status == 400 && !error) {
        setError("Invalid artist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchAlbums = async () => {
      const response = await fetch("/api/albums/" + artist_id);
      const json = await response.json();

      if (response.ok && JSON.stringify(json) !== JSON.stringify(albums)) {
        setAlbums(json);
      } else if (response.status == 404 && !error) {
        setError("Album does not exist");
      } else if (response.status == 400 && !error) {
        setError("Invalid album");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchCredits = async () => {
      const response = await fetch(`/api/credits/artist/${artist_id}`);
      const json = await response.json();

      if (response.ok && JSON.stringify(json) !== JSON.stringify(credits)) {
        setCredits(json);
      } else if (response.status == 400 && !error) {
        setError("Invalid artist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchMemberships = async () => {
      const response = await fetch("/api/memberships/artist/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setMemberships(json);
      } else if (response.status == 400 && !error) {
        setError("Invalid artist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchTitles = async () => {
      const response = await fetch("/api/songs/artist/titles/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setTitles(json);
      } else if (response.status == 400 && !error) {
        setError("Invalid artist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    fetchArtist();
    fetchAlbums();
    fetchCredits();
    fetchMemberships();
    fetchTitles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <PageNotFoundPage msg={error} />;

  // console.log(credits);

  return (
    <div className="artist-page-wrapper">
      <div className="padding">
        <div className="artist-page">
          <Helmet>
            {artists && <title>{artists[0].english_name} - dubu</title>}
            {!artists && <title>loading artist page - dubu</title>}
            {artists && (
              <meta
                name="keywords"
                content={
                  "kpop, dubu, database, kpop credits, " + artists[0].english_name
                }
              />
            )}
            {!artists && (
              <meta
                name="keywords"
                content={"kpop, dubu, database, kpop credits"}
              />
            )}
            {artists && (
              <meta property="og:title" content={artists[0].english_name} />
            )}
            {artists && (
              <meta property="og:image" content={artists[0].picture_url} />
            )}
            <meta property="og:description" content="artist page" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image:width" content="500" />
            <meta property="og:image:height" content="500" />
            {artists && (
              <meta
                name="description"
                content={"Artist Page for " + artists[0].english_name}
              />
            )}
          </Helmet>
          {artists && (
            <ArtistPageHeader
              key={artists[0]._id}
              artist={artists[0]}
            />
          )}

          <div className="artist-page-body">
            {artists && (
              <ArtistPageLeftCard
                key={artists[0]._id}
                artist={artists[0]}
                memberships={memberships}
              />
            )}

            <div className="artist-page-right-tile">
              <div className="artist-data">
                {titles && titles[0] && (
                  <div className="artist-title-details">
                    <div className="list-description">
                      {titles && titles.length > 0 && (
                        <h4>
                          {/* <Link to="./songs" state={{ id: artist_id }}> */}
                          title tracks
                          {/* </Link> */} ({titles.length})
                        </h4>
                      )}
                      <Link className="see-all" to="./songs" state={{ id: artist_id }}>
                        <u> see all songs</u>
                      </Link>
                    </div>
                    <div className="display-list">
                      {titles.slice(0, 6).map((title) => (
                        <SongDisplayList
                          key={title.song_id}
                          song={title}
                          name={title.english_name}
                          picture_url={title.picture_url}
                          year={title.year}
                          artist={undefined}
                          artist_ids={undefined}
                        />
                      ))}
                      <br></br>
                    </div>

                  </div>
                )}
                {albums && albums[0] && (
                  <div className="artist-title-details">
                    <div className="list-description">
                      {albums.length > 0 && (
                        <h4>
                          {/* <Link to="./albums" state={{ id: artist_id }}> */}
                          albums
                          {/* </Link> */} ({albums.length})
                        </h4>
                      )}
                      <Link className="see-all" to="./albums" state={{ id: artist_id }}>
                        <u>see all albums</u>
                      </Link>
                    </div>
                    <div className="display-list">
                      {albums.slice(0, 6).map((album) => (
                        <AlbumList key={album._id} album={album} />
                      ))}
                      <br></br>
                    </div>
                  </div>
                )}

                {credits && credits[0] && (
                  <div className="individual-credits">
                    <CreditDetails key={artist_id} artist_id={artist_id} credits={credits} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="all-info">
          {artists && artists[0].logged_at && (
            <i>
              data last updated on{" "}
              {artists[0].logged_at.toString().slice(0, 10)}.
            </i>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtistPage;
