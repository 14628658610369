import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AllSongDetails from "../../components/ViewAll/AllSongsListDetails";
import ArtistHeaderDetails from "../../components/ViewAll/ArtistHeaderDetails";
import PageNotFoundPage from "../PageNotFoundPage";
import { Helmet } from "react-helmet-async";
import { encodeId } from "../../components/HelperMethods";
import "./Artist.css";

type ComparisonKey = "oldest" | "newest" | "alphabetical";

const comparisons: Record<ComparisonKey, (a: Song, b: Song) => number> = {
  oldest: (a, b) =>
    new Date(a.release_date).getTime() - new Date(b.release_date).getTime(),
  newest: (a, b) =>
    new Date(b.release_date).getTime() - new Date(a.release_date).getTime(),
  alphabetical: (a, b) => a.english_name.localeCompare(b.english_name),
};

function overlap(arr1: any[], arr2: string | any[]) {
  return arr1.some((item) => arr2.includes(item));
}

function ArtistCreditsPage() {
  let { state } = useLocation();
  const [artists, setArtist] = useState<Artist[] | null>(null);
  const [songs, setSongs] = useState<SongAllDetails[] | null>([]);
  const [allSongs, setAllSongs] = useState<SongAllDetails[] | null>([]);
  const [selectedOrder, setSelectedOrder] = useState("newest");
  const [selectedFilters, setSelectedFilters] = useState(["F", "L", "C", "A"]);
  const [error, setError] = useState<string | null>(null);

  let artist_id;
  if (!state) {
    artist_id = Number(window.location.href.split("/").slice(-2)[0]);
  } else {
    artist_id = state.id;
  }

  useEffect(() => {
    const fetchArtist = async () => {
      const response = await fetch("/api/artists/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setArtist(json);
      } else if (response.status == 404 && !error) {
        setError("Artist does not exist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchSongs = async () => {
      const response = await fetch("/api/credits/artist/" + artist_id);
      var json = await response.json();

      if (response.ok) {
        json.sort(
          (a: { release_date: string }, b: { release_date: string }) =>
            new Date(b.release_date).getTime() -
            new Date(a.release_date).getTime()
        );
        setSongs(json);
        setAllSongs(json);
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    fetchArtist();
    fetchSongs();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="artistCredits">
        <Helmet>
          {artists && <title>{artists[0].english_name} credits - dubu</title>}
          {!artists && <title>loading credits - dubu</title>}
          {artists && (
            <meta
              name="keywords"
              content={
                "kpop, dubu, database, kpop credits, credits, " +
                artists[0].english_name
              }
            />
          )}
          {!artists && (
            <meta
              name="keywords"
              content={"kpop, dubu, database, kpop credits, credits"}
            />
          )}
          {artists && (
            <meta
              name="description"
              content={
                "Song production credits list for " + artists[0].english_name
              }
            />
          )}
        </Helmet>
        {artists && (
          <ArtistHeaderDetails artist={artists && artists[0]} type="credits" />
        )}
        <div className="credits-header">
          <div className="select-container">
            <select
              multiple
              value={selectedFilters}
              onChange={(e) => {
                const options = [...e.target.selectedOptions];
                const values = options.map((option) => option.value);
                setSelectedFilters(values);
                if (allSongs) {
                  setSongs(
                    allSongs.filter((x) => overlap(values, x.credit_type))
                  );
                }
              }}
            >
              <option value="F">featured</option>
              <option value="L">lyrics</option>
              <option value="C">composing</option>
              <option value="A">arranging</option>
            </select>

            <select
              value={selectedOrder}
              onChange={(e) => {
                const order = e.target.value;
                setSelectedOrder(order);
                if (songs) {
                  setSongs(songs.sort(comparisons[order as ComparisonKey]));
                }
              }}
            >
              <option value="newest">newest to oldest</option>
              <option value="oldest">oldest to newest</option>
              <option value="alphabetical">alphabetical</option>
            </select>
          </div>
          <div className="icon-view-links">
            🖼 icon views:{" "}
            <h5>
              <Link
                to={`/artist/${encodeId(artist_id)}/credits-featured`}
                state={{ id: artist_id }}
              >
                {" "}
                FEATURED
              </Link>{" "}
              <Link
                to={`/artist/${encodeId(artist_id)}/credits-written`}
                state={{ id: artist_id }}
              >
                {" "}
                WRITTEN
              </Link>{" "}
              <Link
                to={`/artist/${encodeId(artist_id)}/credits-composed`}
                state={{ id: artist_id }}
              >
                {" "}
                COMPOSED
              </Link>{" "}
              <Link
                to={`/artist/${encodeId(artist_id)}/credits-arranged`}
                state={{ id: artist_id }}
              >
                {" "}
                ARRANGED
              </Link>
            </h5>
          </div>
        </div>
        <AllSongDetails songs={songs} credits={true} />
      </div>
    </div>
  );
}

export default ArtistCreditsPage;
